import WebpImage from '../../WebpImage';
import React from 'react';
import './styles.scss';


const Press = () => {

  return (
    <section className='fb-press'>
      <h2 className='press-title'>Don't just take our word for it.</h2>
      <div className='press-wrapper'>
        <div className='press-item'>
          <WebpImage className='press-image' fileName='Pages/Intake/buzzfeed.svg'/>
          <p className='press-body'>In short, the lenses work and are comfortable. I’ve been wearing them for a few days in a variety of climated and situations, and I haven’t had any issues. They’re easy to insert, easy to take out, and they haven’t dried out on me.”</p>
          <a target="_blank" href="https://www.buzzfeed.com/maitlandquitmeyer/life-changing-things-to-try-in-march-2017#10607512" className='press-read-more'>Read more</a>
        </div>

        <div className='press-item'>
          <WebpImage className='press-image' fileName='Pages/Intake/vogue.svg' />
          <p className='press-body'>“Hubble’s daily-use contacts come at a relative bargain and in zippy, colorful packaging by the Brooklyn design firm Athletics. What good is a contact-lens reboot if it’s not easy on the eyes?”</p>
          <a target="_blank" href="https://www.vogue.com/article/best-wellness-subscription-services-hubble-tulip-lola-daily-harvest" className='press-read-more'>Read more</a>
        </div>

        <div className='press-item'>
          <WebpImage className='press-image' fileName='Pages/Intake/mashable.svg' />
          <p className='press-body'>“The company joins the ranks of Harry’s and Dollar Shave Club — which both offer razors directly to consumers — as well as Warby Parker, which makes glasses and lenses under its own brand.”</p>
          <a target="_blank" href="https://mashable.com/article/hubble-contact-lenses-deliver-startup-warby-parker" className='press-read-more'>Read more</a>
        </div>
      </div>
    </section>
  )
}

export default Press;
