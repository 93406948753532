import React from 'react';
import './styles.scss';

const HappinessGuarantee = () => {
  return (
    <section className='happiness'>
      <div className='content-container'>
        <h1>Our Happiness Guarantee</h1>
        <div className='description'>
          <h2>We see what you want.</h2>
          <p>
            If you don’t love your contact lenses, you can return any unopened
            boxes within 14 days for a full refund. Cancel your subscription
            at any time.
          </p>
        </div>
      </div>
    </section>
  );
};

export default HappinessGuarantee;
