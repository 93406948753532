import React from "react";
import Nav from "../components/Intake/Nav";
import Hero from "../components/Intake/Hero";
import CallToAction from "../components/Intake/CallToAction";
import WhyHubble from "../components/Intake/WhyHubble";
import HappinessGuarantee from "../components/Intake/HappinessGuarantee";
import Characteristics from "../components/Intake/Characteristics";
import Press from "../components/Intake/Press";
import CustomerReviews from "../components/Intake/CustomerReviews";
import OrderSection from "../components/Intake/OrderSection";
import "../styles/pages/intake.scss"

const IntakePage = () => {
  return (
    <div className="hubble-content">
      <Nav />
      <Hero />
      <CallToAction />
      <WhyHubble />
      <HappinessGuarantee/>
      <Characteristics/>
      <Press />
      <CustomerReviews/>
      <OrderSection /> 
    </div>
  );
}

export default IntakePage