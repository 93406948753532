import WebpImage from '../../WebpImage'
import React from 'react';
import './styles.scss';

const CallToAction = ({isHydroSkyhy}) => {
  const img = isHydroSkyhy ? 'Pages/Intake/hubble-lens.png' : 'Pages/Intake/hubble-box.png'

  return (
    <section className={isHydroSkyhy ? "call-to-action-hydro-skyhy" : 'call-to-action'}>
      <div className='content-container'>
      <div className='copy-wrapper'>
          <h3 className='cta-headline'>
            Always running out of lenses? <br />
            Forget to re-order? <br />
            Caught in your last pair?
          </h3>
          <p className='cta-body'>
            Hubble makes buying contacts easy. Our best-in-class subscription
            service delivers affordable, daily contacts right to your door every
            month.
          </p>
          <p className='cta-body'>
            With subscription personalization, no-hassle policies, and amazing
            customer service, we're going to change the way you look at buying
            contacts.
          </p>

          <a href='#order-section' className='start-button'>
            Start your subscription {isHydroSkyhy ? "" : "for $1"}
          </a>
        </div>
        <div className='image-wrapper'>
          <WebpImage wrapperClassName='cta-image' className='cta-image' fileName={img} />
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
