import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import './styles.scss';
import { classic, hydroSkyhy } from './reviews';
import WebpImage from '../../WebpImage';

const CustomerReviews = ({isHydroSkyhy}) => {
  const [reviewsData, setReviewsData] = useState(isHydroSkyhy ? hydroSkyhy : classic)

  useEffect(() => {
    setReviewsData(isHydroSkyhy ? hydroSkyhy : classic)
  }, [isHydroSkyhy])

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlickButton />,
    prevArrow: <SlickButton />
  }

  return (
    <div className="customer-reviews">
      <div className="content-container">
        <div className="customer-reviews-container">
          <h3>Customer reviews</h3>
          <Slider {...settings} className="reviews-container">
            {reviewsData.map((item, index) => (
              <ReviewCard {...item} key={index} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
};

const ReviewCard = ({ message, author }) => {
  return (
    <div className='review-card'>
      <p className='message'>{message}</p>
      <p className='author'>-{author}</p>
    </div>
  )
}

const SlickButton = (props) => {
  return (
    <button className={`arrow-button ${props.className}`} onClick={props.onClick}>
      <WebpImage objectFit='cover' fileName='Pages/Intake/Triangle.svg' alt='' />
    </button>
  )
}

export default CustomerReviews;
