import WebpImage from '../../WebpImage';
import React from 'react';
import './styles.scss';


const WhyHubble = ({isHydroSkyhy}) => {
  return (
    <section className='why-hubble'>
      <h2 className='headline'>Why Hubble?</h2>

      <div className='reasons-wrapper'>
        <div className='reason'>
          <div className='image-wrapper'>
            <WebpImage className='image' fileName='Pages/Intake/millions-icon.svg' />
          </div>
          <p className='reason-headline'>
            400+ million contacts sold
          </p>
          <p className='reason-body'>
            Happy customers as far as the eye can see.
          </p>
        </div>

        <div className='reason'>
          <div className='image-wrapper'>
            <WebpImage className='image' fileName='Pages/Intake/one-dollar-icon.svg' />
          </div>
          <p className='reason-headline'>
            Start for $1
          </p>
          <p className='reason-body'>
            {isHydroSkyhy ? "Pay $1 for your first 15 pairs of our classic lenses – or start SkyHy or Hydro by Hubble for a special price." 
            : "Your first 15 pairs for just $1; after that, a 28-day supply is just $39.98."}
          </p>
        </div>

        <div className='reason'>
          <div className='image-wrapper'>
            <WebpImage className='image' fileName='Pages/Intake/never-run-out-icon.svg' />
          </div>
          <p className='reason-headline'>
            Never run out
          </p>
          <p className='reason-body'>
            Auto-ship to your door every 28 days.
          </p>
        </div>

        <div className='reason'>
          <div className='image-wrapper'>
            <WebpImage className='image' fileName='Pages/Intake/boxes-icon.svg' />
          </div>
          <p className='reason-headline'>
            Personalized for you
          </p>
          <p className='reason-body'>
            Set your delivery frequency to fit your usage.
          </p>
        </div>

        <div className='reason'>
          <div className='image-wrapper'>
            <WebpImage className='image' fileName='Pages/Intake/blister-icon.svg' />
          </div>
          <p className='reason-headline'>
            No-hassle policies
          </p>
          <p className='reason-body'>
            Cancel any time and for any reason.
          </p>
        </div>

        <div className='reason'>
          <div className='image-wrapper'>
            <WebpImage className='image' fileName='Pages/Intake/lock-icon.svg' />
          </div>
          <p className='reason-headline'>
            Secure payment
          </p>
          <p className='reason-body'>
            Safety first across all payment platforms.
          </p>
        </div>
      </div>
    </section>
  )
}

export default WhyHubble;
