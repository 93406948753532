export const classic = [
  {
    message: `
      "I'm glad I switched to Hubble. I put in a fresh pair every
      day. Never have to worry about reordering. So crisp and
      comfortable!!!"
    `,
    author: 'Zulema J.',
  },
  {
    message: `
      "This is literally the only brand of contacts that works for
      me. I've tried all of the major brands and was ready to give
      up. I love my Hubble contacts!"
    `,
    author: 'Anna H.',
  },
  {
    message: `
      "Omg! When I put them on I was extremely impressed. I'm in
      love. I can not even remotely tell they are in. I will be a
      forever subscriber."
    `,
    author: 'Charlie K.',
  },
]

export const hydroSkyhy = [
  {
    message: `
      "I’m glad I switched to Hubble.
      I put in a fresh pair every day. Never have to worry about
      reordering. So crisp and comfortable!!!"
    `,
    author: 'Zulema J.',
  },
  {
    message: `
      "This is literally the only brand of contacts that works for
      me. I've tried all of the major brands and was ready to give
      up. I love my Hubble contacts!"
    `,
    author: 'Anna H.',
  },
  {
    message: `
      "Omg! When I put them on I was extremely impressed. I'm in
      love. I can not even remotely tell they are in. I will be a
      forever subscriber."
    `,
    author: 'Charlie K.',
  },
];
