import React from 'react';
import './styles.scss';

const Hero = ({isHydroSkyhy}) => {
  return (
    <section className={isHydroSkyhy ? "fb-hero-hydro-skyhy" : 'fb-hero'}>
      <div className='content-container'>
        <div className='image-block'>
          <div className='image' />
        </div>
        <div className='text-block'>
          <div className='text-container'>
            <h1 className='hero-title'>{isHydroSkyhy ? "We make contacts easy." : "Welcome to Hubble"}</h1>
            <p className='hero-subtitle'>{isHydroSkyhy ? "Get your first order for as little as $1." : "We make contacts easy."}</p>
            <a href='#order-section' className='start-button'>
              {isHydroSkyhy ? "Start Your Subscription" : "Start for $1"}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
