import WebpImage from '../../WebpImage';
import React from 'react';
import './styles.scss';

const Characteristics = () => {
  return (
    <section className='characteristics'>
      <div className='content-container'>
        <div className='image-block'>
          <WebpImage fileName='Pages/Intake/hubble-box-and-lenses.png' />
        </div>
        <div className='text-block'>
          <div className='text-container'>
            <h1>Our contacts are easy on your eyes and wallet.</h1>
            <ul>
              <li>FDA-approved</li>
              <li>Made from methafilcon A hydrogel material</li>
              <li>Have 55% water content</li>
              <li>Provide UV protection</li>
              <li>
                Designed for easy insertion, all-day comfort, and crystal clear
                vision
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Characteristics;
